import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { $fileAxios, cacheableAxios } from "@/utils/axios";
import store from "@/store";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { FileOpener, FileOpenerOptions } from "@capacitor-community/file-opener";
import write_blob from "capacitor-blob-writer";
import { Capacitor } from "@capacitor/core";

export async function getFile(config: AxiosRequestConfig, showModal = true): Promise<any> {
  return await $fileAxios({
    ...config,
    responseType: "arraybuffer",
  })
    .then(async (response: AxiosResponse) => {
      const mimeType = response.headers["content-type"].toLowerCase();
      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      if (showModal) {
        await store.dispatch("modal/setContent", url, { root: true });
        await store.dispatch("modal/setStatus", true, { root: true });
      }
      return url;
    })
    .catch(async (error: AxiosError) => {
      await dispatchError(error);
    });
}

export function dataURLtoFile(dataurl: any, filename: any) {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function downloadFile(config: AxiosRequestConfig, filename = "export.csv"): Promise<any> {
  return await $fileAxios({
    ...config,
    responseType: "arraybuffer",
  })
    .then(async (response: AxiosResponse) => {
      if (Capacitor.isNativePlatform()) {
        await download(filename, new Blob([response.data]));
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    })
    .catch((error: AxiosError) => {
      dispatchError(error);
    });
}

const writeFile = async (blob: Blob, path: string) => {
  return await write_blob({
    blob: blob,
    path: path,
    directory: Directory.Cache,
    recursive: true,
    fast_mode: true,
  });
};

async function download(filename: string, blob: Blob) {
  const fileUrl = await writeFile(blob, filename);

  const fileOpenerOptions: FileOpenerOptions = {
    filePath: fileUrl,
  };

  await FileOpener.open(fileOpenerOptions)
    .then(() => {
      // 'File is opened'
    })
    .catch((error) => {
      console.error(error);
    });
}

async function dispatchError(error: AxiosError): Promise<any> {
  if (error.response?.status === 422) {
    const blob = new Blob([error.response.data], {
      type: "application/json",
    });
    const fr = new FileReader();

    fr.onload = () => {
      //@ts-ignore
      const responseError = JSON.parse(fr.result);
      store.dispatch(
        "global-message/setMessage",
        {
          text: responseError.globalErrors[0],
          type: "error",
        },
        { root: true }
      );
    };

    fr.readAsText(blob);
  } else {
    await store.dispatch(
      "global-message/setMessage",
      {
        text: error.message,
        type: "error",
      },
      { root: true }
    );
  }
}

export async function getAuthMedia(config: AxiosRequestConfig): Promise<any> {
  const cacheAxios: any = await cacheableAxios();
  return cacheAxios({
    ...config,
    responseType: "arraybuffer",
  }).then((response: AxiosResponse) => {
    const mimeType = response.headers["content-type"].toLowerCase();
    //@ts-ignore
    const imageBase64 = new Buffer(response.data, "binary").toString("base64");

    return "data:" + mimeType + ";base64," + imageBase64;
  });
}
